import axios from "axios";
import { STORE_BASE_URL } from "./BaseURL";
import getHeaders from "../constants/headers";

export const storeOrders = (storeId, data) => {
  return axios({
    method: "post",
    url:
      STORE_BASE_URL +
      "/store/api/secure/v1/store/" +
      storeId +
      "/store-order-by-status",
    // for android use
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};

export const getQrCodeImages2 = (id, storeId, orderId) => {
  return axios({
    method: "get",
    url:
      STORE_BASE_URL +
      "/store/api/secure/v1/store/" +
      storeId +
      "/store-customer/ " +
      id +
      "/order/" +
      orderId +
      "/qr-code/png",
    headers: getHeaders(),
  });
};

export const getQrCodeTextLabel = (id, storeId, orderId) => {
  return axios({
    method: "get",
    url:
      STORE_BASE_URL +
      "/store/api/secure/v1/store/" +
      storeId +
      "/store-customer/ " +
      id +
      "/order/" +
      orderId +
      "/qr-code-text-print",
    headers: getHeaders(),
  });
};


export const storeOrderCancel = (storeId,storeCustomerId,orderId, data) => {
  return axios({
    method: "DELETE",
    url:
      STORE_BASE_URL +
      "/store/api/secure/v1/store/" +
      storeId +
      "/store-customer/ " +
      storeCustomerId +
      "/order/" +
      orderId +
      "/cancel",
    // for android use
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};

export const storeOrdersWithPaginationAndSearch = (storeId, data) => {
  return axios({
    method: "post",
    url:
      STORE_BASE_URL +
      "/store/api/secure/v3/store/" +
      storeId +
      "/store-order-by-status",
    // for android use
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};

// curl --location --request DELETE 'store/api/secure/v1/store/37/store-customer/22167/order/13625/cancel' \
// --header 'Authorization: Bearer eyJhbGciOiJIUzUxMiJ9.eyJhdXRob3JpdGllcyI6WyJST0xFX1NVUEVSX0FETUlOIl0sImRldmljZVRva2VuIjoiZDQxZDhjZDk4ZjAwYjIwNGU5ODAwOTk4ZWNmODQyN2UiLCJsb2dnZWRJblVzZXJJZCI6Ik9JVDM3NTcyIiwibG9nZ2VkSW5Vc2VyQnJhbmRJZCI6IjEiLCJzdWIiOiIxIiwiaWF0IjoxNzM0NTAxMTEyLCJleHAiOjE3MzUxMDU5MTJ9.-xYXPhCLd70ZhaNJm0P_IuPZHvSkKjrWgb_e1TIIE-Z8Xg0viGcvLkq5TstjmpfxgM2cPxFbikyQuZ27Z_4OwQ' \
// --header 'Content-Type: application/json' \
// --header 'Cookie: JSESSIONID=D5A82530CE33E6A3A566BAB02D081AF9' \
// --data '{
//     "remark" : "Reuested By User"
// }'