import React from 'react'

// curl --location 'https://dhobicart.in/store/api/secure/v1/notification-config/default-list' \
// --header 'Authorization: Bearer eyJhbGciOiJIUzUxMiJ9.eyJhdXRob3JpdGllcyI6WyJST0xFX1NVUEVSX0FETUlOIl0sImRldmljZVRva2VuIjoiZDQxZDhjZDk4ZjAwYjIwNGU5ODAwOTk4ZWNmODQyN2UiLCJsb2dnZWRJblVzZXJJZCI6Ik9JVDM3NTcyIiwibG9nZ2VkSW5Vc2VyQnJhbmRJZCI6IjEiLCJzdWIiOiIxIiwiaWF0IjoxNzM1Nzk5NDM1LCJleHAiOjE3MzY0MDQyMzV9.OlypwIIny6ATuXl6mYHZKL5XaeC272pw1HVyIp6G-ALCeixhebiaK0HxnL39mKYB8V6kY3DvnRNX8xSuUvQckQ' \
// --header 'Cookie: JSESSIONID=EB155D939C74695C32CA684D0F968F29; JSESSIONID=68C8210FC27110563F06A19B91BFD1CD'

const ViewNotificationTemplate = () => {
  return (
    <div>ViewNotificationTemplate</div>
  )
}

export default ViewNotificationTemplate