import axios from "axios";
import { PRICElIST_BASE_URL } from "./BaseURL";
import getHeaders from "../constants/headers";

export const AddGarmentCategoryUpload = (uploadFile) => {
  const formData = new FormData();
  formData.append("garments", uploadFile); // Assuming 'file' is the file object you want to upload

  return axios({
    method: "post",
    url:
      PRICElIST_BASE_URL +
      "/api/secure/v1/admin/garment/add-garment-category-new",
    data: formData,
    headers: getHeaders("multipart/form-data"),
  });
};

export const AddPriceGarmentServiceUpload = (uploadFile, priceId, serviceUnit) => {
  const formData = new FormData();
  formData.append("price", uploadFile); // Assuming 'file' is the file object you want to upload
  formData.append("priceId", priceId); // Assuming 'file' is the file object you want to upload
  formData.append("serviceUnit", serviceUnit); // Assuming 'file' is the file object you want to upload

  return axios({
    method: "post",
    url: PRICElIST_BASE_URL + "/api/secure/v1/price-garment-service",
    data: formData,
    headers: getHeaders("multipart/form-data"),
  });
};

export const AddPackageGarmentServiceUpload = (
  uploadFile,
  priceId,
  serviceUnit
) => {
  const formData = new FormData();
  formData.append("price", uploadFile); // Assuming 'file' is the file object you want to upload
  formData.append("priceId", priceId); // Assuming 'file' is the file object you want to upload
  formData.append("serviceUnit", serviceUnit); // Assuming 'file' is the file object you want to upload

  return axios({
    method: "post",
    url: PRICElIST_BASE_URL + "/api/secure/v1/package-garment-service",
    data: formData,
    headers: getHeaders("multipart/form-data"),
  });
};

export const getBrandPriceCode = (brandId) => {
  return axios({
    method: "get",
    url: PRICElIST_BASE_URL + "/api/secure/v1/brand/" + brandId + "/price-code",
    headers: getHeaders(),
  });
};

export const getServiceUnit = () => {
  return axios({
    method: "get",
    url: PRICElIST_BASE_URL + "/api/secure/v1/service-unit",
    headers: getHeaders(),
  });
};