import axios from "axios";
import { BRAND_BASE_URL } from "./BaseURL";
import getHeaders from "../constants/headers";

export const getBrandWhatsAppConfig = async (brandId) => {
  return axios({
    method: "get",
    url: BRAND_BASE_URL + "/store/api/secure/brand-whatsapp-config/all", // for android use

    headers: getHeaders(),
  });
};

export const postWhatsAppLeftCountUpdate = async (id,data) => {
  return axios({
    method: "post",
    url:
      BRAND_BASE_URL +
      "/store/api/secure/brand-whatsapp-config/" +
      id +
      "/message", // for android use
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};

export const AddBrandWhatsAppConfigData = async (id, data) => {
  return axios({
    method: "post",
    url: BRAND_BASE_URL + "/store/api/secure/brand-whatsapp-config/" + id,
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};

export const EditBrandWhatsAppConfigData = async (id, data) => {
  return axios({
    method: "put",
    url: BRAND_BASE_URL + "/store/api/secure/brand-whatsapp-config/" + id,
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};

