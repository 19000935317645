import React, { useEffect, useState } from "react";
import { Col, Container, Nav, Navbar, Row, Button } from "react-bootstrap";
import "../index.css";
import { Link, useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";

import { getBrandWhatsAppConfig, postWhatsAppLeftCountUpdate } from "../../services/BrandWhatsAppConfig";
import { errorMessage, successMessage } from "../../utils/alertMessage";
import { getSingleBrands } from "../../services/brandApi";

const BrandWhatsAppConfig = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [brandList, setBrandList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedViewAll, setSelectedViewAll] = useState("");

  const [leftCountValue, setLeftCountValue] = useState("");
  const [leftCountValueError, setLeftCountValueError] = useState("");

  const fetchAllBrands = () => {
    setLoading(true);
    getBrandWhatsAppConfig()
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          setBrandList(Res);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

   const [brandInfo, setBrandInfo] = useState({});
  
    const fetchSingleBrand = (brandId) => {
      getSingleBrands(brandId)
        .then((response) => {
          if (!response.data.error) {
            const Res = response.data;
            setBrandInfo(Res);
          }
        })
        .catch((err) => {
          console.error("Error:", err);
        });
    };

  useEffect(() => {
    fetchAllBrands();
  }, []);

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value); // Update searchQuery state with the input value
  };
  // Function to filter orderData based on search query

  const filteredData = brandList.filter((item) => {
    const query = searchQuery.toLowerCase();
    return item.senderId.toLowerCase().includes(query);
  });

  const [displayLeftCountUpdate, setDisplayLeftCountUpdate] = useState(false);
  const [displayViewAll, setDisplayViewAll] = useState(false);

  const [submitButtonPressed, setSubmitButtonPressed] = useState(false);

  const onClickLeftCountUpdate = (item) => {
      setSelectedViewAll(item);
      setDisplayLeftCountUpdate(true);
      fetchSingleBrand(item.brandId);
  };
  const onHideLeftCountUpdate = () => {
    setLeftCountValueError("");
    setDisplayLeftCountUpdate(false);
  };

  const onClickViewAll = (item) => {
    setSelectedViewAll(item);
    setDisplayViewAll(true);
  };
  const onHideViewAll = () => {
    setDisplayViewAll(false);
  };

  const renderDialogLeftCountUpdate = () => (
    <Dialog
      header="Update Left Count"
      visible={displayLeftCountUpdate}
      onHide={() => onHideLeftCountUpdate()}
      breakpoints={{ "960px": "75vw" }}
      style={{ width: "50vw" }}
    >
      <div>
        {brandInfo.enabledAutoWhatsappNotification === true ? (
          <form onSubmit={handleLeftCountUpdate}>
            <div className="row mb-3">
              <div className="col-md-12">
                <label htmlFor="qrCodeCount" className="form-label">
                  Left Count
                </label>
              </div>

              <InputText
                type="number"
                style={{ width: "100%" }}
                id="leftCountValue"
                name="leftCountValue"
                value={leftCountValue}
                onChange={(e) => {
                  setLeftCountValue(e.target.value);
                }}
              />
            </div>
            {leftCountValueError && (
              <p style={{ color: "red" }}>{leftCountValueError}</p>
            )}

            {leftCountValue >= 0 && (
              <div className="col-md-12 text-center">
                <button
                  type="submit"
                  className="move-btn move-btn-su"
                  disabled={submitButtonPressed}
                >
                  Submit
                </button>
              </div>
            )}
          </form>
        ) : (
          <div>
            <p>Auto Whatsapp Notification Is Not Enabled So This Feature Can Not Be Used Right Now</p>
          </div>
        )}
      </div>
    </Dialog>
  );

  const renderDialogViewAll = () => (
    <Dialog
      header="ALL Details"
      visible={displayViewAll}
      onHide={() => onHideViewAll()}
      breakpoints={{ "960px": "75vw" }}
      style={{ width: "50vw" }}
    >
      <div>
        <div className="row mb-3">
          <div className="col-md-12">
            <p style={{ wordWrap: "break-word", color: "#000" }}>
              Token - {selectedViewAll.token}
            </p>
            <p>
              {selectedViewAll.apiVersion &&
                "Api Version -" + selectedViewAll.apiVersion}{" "}
            </p>
          </div>
        </div>
      </div>
    </Dialog>
  );

  const handleLeftCountUpdate = (event) => {
    event.preventDefault();

    if (leftCountValue === "" || null) {
      setLeftCountValueError("Please Fill Left Count Value");
      return;
    }

    const data = {
      id: selectedViewAll.id,
      brandId: selectedViewAll.brandId,
      leftCount: leftCountValue,
    };

    postWhatsAppLeftCountUpdate(selectedViewAll.brandId, data)
      .then((response) => {
        // handle the response
        if (response.data.status) {
          successMessage(response.data.message);
          fetchAllBrands();
          
        } else {
          errorMessage(response.data.message);
        }
        setLeftCountValue("");
        setDisplayLeftCountUpdate(false);
      })
      .catch((error) => {
        // handle errors
        errorMessage("Update Failed");
        setDisplayLeftCountUpdate(false);
      });
  };

  return (
    <div className="form-demo background-image-login">
      <Navbar
        expand="lg"
        className="bg-body-tertiary"
        style={{
          backgroundImage: "linear-gradient(to bottom, #1752aa, #2b70d7)",
        }}
      >
        <Container>
          <Button
            style={{ background: "none", border: "none", left: -20 }}
            onClick={() => navigate('/')}
          >
            <i className="pi pi-arrow-left" style={{ fontSize: "2rem" }}></i>
          </Button>
          <Navbar.Brand href="/">
            <img style={{ width: "46px" }} src="/Img/1.png" alt="logo" />
          </Navbar.Brand>

          <Navbar.Toggle />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="me-auto-new">
              <Nav.Link className="nav-link-design" href="/">
                Home
              </Nav.Link>
              <Nav.Link className="nav-link-design" href="/logout">
                Logout
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container fluid>
        <Row className="mt-3 ml-3">
          <Col>
            <InputText
              type="text"
              value={searchQuery}
              onChange={handleSearchInputChange}
              placeholder="Search Sender Id"
            />
          </Col>
          <Col style={{ display: "flex", justifyContent: "flex-end" }}>
            <Link
              to="/add-brand-whats-app-config"
              className="move-btn-a move-btn-mu center-text"
              variant="primary"
              style={{
                backgroundColor: "#2b70d7", // Change background color if selected
                color: "white", // Change text color if selected
                textAlign: "center",
                width: 120,
              }}
            >
              Add
            </Link>
          </Col>
        </Row>
        <Row>
          <Col style={{ margin: 10 }}>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h3>
                  <i
                    className="pi pi-spin pi-spinner"
                    style={{ color: "blue", fontSize: "20px" }}
                  ></i>{" "}
                  Brands WhatsApp Config List is Loading
                </h3>
              </div>
            ) : filteredData.length > 0 ? (
              <div>
                <div style={{ textAlign: "center" }}>
                  <h3>Brands WhatsApp Config List </h3>
                </div>
                <main id="main" className="main mb-3">
                  <section className="section">
                    <div className="col-lg-12">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>S. No.</th>
                            <th>Brand Id</th>
                            <th>Sender Id</th>
                            <th>Handle By</th>
                            <th>Left Count</th>
                            <th>Used Count</th>
                            <th>Update Count</th>
                            <th>Edit</th>
                            <th>View Other Info</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredData.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{item.brandId}</td>
                              <td>{item.senderId}</td>
                              <td>{item.handleBy}</td>
                              <td>{item.leftCount}</td>
                              <td>{item.usedCount}</td>
                              <td>
                                {item.handleBy === "dhobicart" && (
                                  <button
                                    type="button"
                                    className="btn"
                                    onClick={() => onClickLeftCountUpdate(item)}
                                  >
                                    <i
                                      className="pi pi-upload"
                                      style={{
                                        color: "blue",
                                        fontSize: "20px",
                                      }}
                                    ></i>
                                  </button>
                                )}
                              </td>
                              <td>
                                <button
                                  type="button"
                                  className="btn"
                                  onClick={() =>
                                    navigate("/edit-brand-whats-app-config", {
                                      state: item,
                                    })
                                  }
                                >
                                  <i
                                    className="pi pi-pencil"
                                    style={{
                                      color: "blue",
                                      fontSize: "20px",
                                    }}
                                  ></i>
                                </button>
                              </td>
                              <td>
                                <button
                                  type="button"
                                  className="btn"
                                  onClick={() => onClickViewAll(item)}
                                >
                                  <i
                                    className="pi pi-eye"
                                    style={{ color: "blue", fontSize: "20px" }}
                                  ></i>
                                </button>
                              </td>
                              <td>{item.status}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </section>
                </main>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h3>No Sender Id Found</h3>
              </div>
            )}
          </Col>
        </Row>
      </Container>
      <div>{renderDialogLeftCountUpdate()}</div>
      <div>{renderDialogViewAll()}</div>
    </div>
  );
};

export default BrandWhatsAppConfig;
