import React, { useState } from "react";
import { Col, Container, Nav, Navbar, Row, Button } from "react-bootstrap";
import "../index.css";
import { useNavigate } from "react-router-dom";
import { brandCustomerUpload } from "../../services/BrandCustomerUpload";
import { errorMessage, successMessage } from "../../utils/alertMessage";
import { AddGarmentCategoryUpload, AddPriceGarmentService } from "../../services/AddGarmentCategoryUpload";

const AddGarmentCategory = () => {
  const navigate = useNavigate();

  const handleFileChange = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    setFileName(file ? file.name : ""); // Update the file name or clear it if no file is selected

    const reader = new FileReader();
    reader.onloadend = () => {
      setSelectedImage(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    } else {
      setSelectedImage(null);
    }
  };

    

  const [fileName, setFileName] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);

  const [submitButtonPressed, setSubmitButtonPressed] = useState(false); 

  const handleGarmentCategoryUpload = (event) => {
    event.preventDefault();
    setSubmitButtonPressed(true);
    const fileInput = event.target.querySelector('input[type="file"]');
    const uploadFile = fileInput.files[0];

    if (!uploadFile) {
      console.error("No file selected");
      return;
    }

    AddGarmentCategoryUpload(uploadFile)
      .then((response) => {
        // handle the response
        if (response.status === true) {
          successMessage("Garment Upload Successfully");
        } else {
          successMessage("Garment Upload Failed");
        }
        setSubmitButtonPressed(false);
        navigate("/");
      })
      .catch((error) => {
        // handle errors
        errorMessage("Garment Upload Failed");
      });
  };

  return (
    <div className="form-demo background-image-login">
      <Navbar
        expand="lg"
        className="bg-body-tertiary"
        style={{
          backgroundImage: "linear-gradient(to bottom, #1752aa, #2b70d7)",
        }}
      >
        <Container>
          <Button
            style={{ background: "none", border: "none", left: -20 }}
            onClick={() => navigate(-1)}
          >
            <i className="pi pi-arrow-left" style={{ fontSize: "2rem" }}></i>
          </Button>
          <Navbar.Brand href="/">
            <img style={{ width: "46px" }} src="/Img/1.png" alt="logo" />
          </Navbar.Brand>

          <Navbar.Toggle />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="me-auto-new">
              <Nav.Link className="nav-link-design" href="/">
                Home
              </Nav.Link>
              <Nav.Link className="nav-link-design" href="/logout">
                Logout
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container fluid>
        <Row>
          <Col style={{ margin: 10 }}>
            <div>
              <div style={{ textAlign: "center" }}>
                <h3>Add Garment Category Upload</h3>
              </div>
              <main id="main" className="main mb-3">
                <section className="section">
                  <div className="col-lg-12">
                    <div>
                      <form onSubmit={handleGarmentCategoryUpload}>
                        <div className="row mb-3">
                          <div
                            className="col-md-12 mt-4"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div></div>
                            <div>
                              <label
                                for="file-upload"
                                className="custom-file-upload"
                              >
                                <i className="pi pi-upload"></i> Choose Garment
                                Category File
                              </label>
                              <input
                                id="file-upload"
                                type="file"
                                onChange={handleFileChange}
                                style={{ display: "none" }}
                              />
                            </div>
                            <div>
                              <i
                                className="pi pi-file"
                                style={{ color: "blue", fontSize: "40px" }}
                              ></i>
                              {fileName && (
                                <p
                                  style={{
                                    alignSelf: "center",
                                    fontSize: 14,
                                    fontWeight: 600,
                                    margin: 10,
                                  }}
                                >
                                  {fileName}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        {selectedImage && (
                          <div className="col-md-12 text-center">
                            <button
                              type="submit"
                              className="move-btn move-btn-su"
                              disabled={submitButtonPressed}
                            >
                              Submit
                            </button>
                          </div>
                        )}
                      </form>
                    </div>
                  </div>
                </section>
              </main>
            </div>
          </Col>
        </Row>
         
      </Container>
    </div>
  );
};

export default AddGarmentCategory;
