import React, { useState } from "react";
import { Col, Container, Nav, Navbar, Row, Button } from "react-bootstrap";
import "../index.css";
import { useNavigate } from "react-router-dom";
import { errorMessage, successMessage } from "../../utils/alertMessage";
import {
  getBrandPriceCode,
  getServiceUnit,
} from "../../services/AddGarmentCategoryUpload";
import { Dropdown } from "primereact/dropdown";
import { getAllBrands, getSingleBrands } from "../../services/brandApi";
import { useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { AddBrandWhatsAppConfigData } from "../../services/BrandWhatsAppConfig";

const AddBrandWhatsAppConfig = () => {
  const navigate = useNavigate();

   

    const [loading, setLoading] = useState(false);
  const [brandList, setBrandList] = useState([]);
 
  const [submitButtonPressed, setSubmitButtonPressed] = useState(false);
  const [input, setInput] = useState({
    brandId: "",
    senderId: "",
    token: "",
    handleBy:"",
    apiVersion:"",
  });
  const [errors, setErrors] = useState({
    brandId: "",
    senderId: "",
    token: "",
    handleBy: "",
    apiVersion: "",
  });

   const HandledList = [
     {
       label: "By Dhobicart",
       value: "dhobicart",
     },
     {
       label: "By Brand",
       value: "brand",
     },
   ];

  const fetchAllBrands = () => {
    setLoading(true);
    getAllBrands()
      .then((response) => {
        if (!response.data.error) {
          const res = response.data.map((item) => ({
            label: item.name,
            value: item.id,
          }));
          setBrandList(res);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };
  
  useEffect(() => {
    fetchAllBrands();
  }, []);

  const [brandInfo, setBrandInfo] = useState({});
  
   const fetchSingleBrand = (brandId) => {
        getSingleBrands(brandId)
          .then((response) => {
            if (!response.data.error) {
              const Res = response.data;
              setBrandInfo(Res);
            }
          })
          .catch((err) => {
            console.error("Error:", err);
          });
      };

  const handleSubmit = () => {
    if(input.senderId === "") {
      setErrors({
        ...errors,
        senderId: "Please Enter Sender Id",
      });
      
    } else if (input.token === "") {
      setErrors({
        ...errors,
        token: "Please Enter Token",
      });
    } else if (input.handleBy === "") {
      setErrors({
        ...errors,
        handleBy: "Please Select Handle By",
      });
    } else {
      setSubmitButtonPressed(true);
      const data = {
        ...input,
        brandId: input.brandId.value,
        handleBy: input.handleBy.value,
        usedCount: 0,
        leftCount: 0,
      };

      if (!data) {
        console.error("No file selected");
        return;
      }


      AddBrandWhatsAppConfigData(input.brandId.value, data)
        .then((response) => {
          // handle the response
          if (response.data.status === true) {
            successMessage(response.data.message);
            setSubmitButtonPressed(false);
             navigate("/brand-whats-app-config");
          } else {
            errorMessage(response.data.message);
            setSubmitButtonPressed(false);
          }
        })
        .catch((error) => {
          // handle errors
          errorMessage("Failed");
        });
    }
  };

  return (
    <div className="form-demo background-image-login">
      <Navbar
        expand="lg"
        className="bg-body-tertiary"
        style={{
          backgroundImage: "linear-gradient(to bottom, #1752aa, #2b70d7)",
        }}
      >
        <Container>
          <Button
            style={{ background: "none", border: "none", left: -20 }}
            onClick={() => navigate("/brand-whats-app-config")}
          >
            <i className="pi pi-arrow-left" style={{ fontSize: "2rem" }}></i>
          </Button>
          <Navbar.Brand href="/">
            <img style={{ width: "46px" }} src="/Img/1.png" alt="logo" />
          </Navbar.Brand>

          <Navbar.Toggle />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="me-auto-new">
              <Nav.Link className="nav-link-design" href="/">
                Home
              </Nav.Link>
              <Nav.Link className="nav-link-design" href="/logout">
                Logout
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container fluid>
        <Row>
          <Col style={{ margin: 10 }}>
            <div>
              <div style={{ textAlign: "center" }}>
                <h3>Add Brand WhatsApp Config</h3>
              </div>
              <main id="main" className="main mb-3">
                <section className="section">
                  <div className="col-lg-12">
                    <div>
                      <div className="mt-3">
                        <label htmlFor="brandId" className="form-label">
                          Brand
                        </label>
                        <Dropdown
                          value={input.brandId}
                          options={brandList}
                          style={{ width: "100%" }}
                          onChange={(e) => {
                            setInput({
                              ...input,
                              brandId: e.target.value,
                            });
                            fetchSingleBrand(e.target.value.value);
                          }}
                          optionLabel="label"
                          placeholder="Select a Brand"
                        />
                        {/*<div className="text-danger mt-1">{errors.priceId}</div>*/}
                      </div>

                      {input.brandId && (
                        <div>
                          {brandInfo.enabledAutoWhatsappNotification ===
                          true ? (
                            <div>
                              <div className="row mt-3">
                                <div className="col-md-6">
                                  <label
                                    htmlFor="senderId"
                                    className="form-label"
                                  >
                                    Sender Id
                                  </label>

                                  <InputText
                                    type="text"
                                    style={{ width: "100%" }}
                                    id="senderId"
                                    name="senderId"
                                    value={input.senderId}
                                    onChange={(e) => {
                                      setInput({
                                        ...input,
                                        senderId: e.target.value,
                                      });
                                      setErrors({
                                        ...errors,
                                        senderId: "",
                                      });
                                    }}
                                  />
                                  {errors.senderId && (
                                    <p style={{ color: "red", margin: 0 }}>
                                      {errors.senderId}
                                    </p>
                                  )}
                                </div>
                                <div className="col-md-6">
                                  <label
                                    htmlFor="apiVersion"
                                    className="form-label"
                                  >
                                    Api Version
                                  </label>

                                  <InputText
                                    type="text"
                                    style={{ width: "100%" }}
                                    id="apiVersion"
                                    name="apiVersion"
                                    value={input.apiVersion}
                                    onChange={(e) => {
                                      setInput({
                                        ...input,
                                        apiVersion: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="mt-3">
                                <div className="row mb-3">
                                  <div className="col-md-12">
                                    <label
                                      htmlFor="token"
                                      className="form-label"
                                    >
                                      Token
                                    </label>

                                    <InputText
                                      type="text"
                                      style={{ width: "100%" }}
                                      id="token"
                                      name="token"
                                      value={input.token}
                                      onChange={(e) => {
                                        setInput({
                                          ...input,
                                          token: e.target.value,
                                        });
                                        setErrors({
                                          ...errors,
                                          token: "",
                                        });
                                      }}
                                    />
                                    {errors.token && (
                                      <p style={{ color: "red", margin: 0 }}>
                                        {errors.token}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div className="mt-3">
                                <div className="row mb-3">
                                  <div className="col-md-12">
                                    <label
                                      htmlFor="handleBy"
                                      className="form-label"
                                    >
                                      Handle By
                                    </label>
                                    <Dropdown
                                      value={input.handleBy}
                                      options={HandledList}
                                      style={{ width: "100%" }}
                                      onChange={(e) => {
                                        setInput({
                                          ...input,
                                          handleBy: e.target.value,
                                        });
                                        setErrors({
                                          ...errors,
                                          handleBy: "",
                                        });
                                      }}
                                      optionLabel="label"
                                      placeholder="Select a Handle By"
                                    />
                                  </div>
                                  {errors.handleBy && (
                                    <p style={{ color: "red", margin: 0 }}>
                                      {errors.handleBy}
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div>
                                <div className="col-md-12 text-center">
                                  <button
                                    onClick={() => handleSubmit()}
                                    className="move-btn move-btn-su"
                                    disabled={submitButtonPressed}
                                  >
                                    Submit
                                  </button>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignContent: "center",
                                margin: 10,
                              }}
                            >
                              <p style={{ fontSize: 20, color: "red" }}>
                                Auto Whatsapp Notification Is Not Enabled So
                                This Feature Can Not Be Used Right Now
                              </p>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </section>
              </main>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AddBrandWhatsAppConfig;
