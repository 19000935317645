import React, { useState } from "react";
import { Col, Container, Nav, Navbar, Row, Button } from "react-bootstrap";
import "../index.css";
import { useNavigate } from "react-router-dom";
import { errorMessage, successMessage } from "../../utils/alertMessage";
import { AddPriceGarmentServiceUpload, getBrandPriceCode, getServiceUnit } from "../../services/AddGarmentCategoryUpload";
import { Dropdown } from "primereact/dropdown";
import { getAllBrands } from "../../services/brandApi";
import { useEffect } from "react";

const AddPriceGarmentService = () => {
  const navigate = useNavigate();

  const handleFileChange = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    setFileName(file ? file.name : ""); // Update the file name or clear it if no file is selected

    const reader = new FileReader();
    reader.onloadend = () => {
      setSelectedImage(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    } else {
      setSelectedImage(null);
    }
  };

  const [fileName, setFileName] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [brandList, setBrandList] = useState([]);
  const [brandPriceList, setBrandPriceList] = useState([]);
  const [serviceUnitList, setServiceUnitList] = useState([]);

  const [submitButtonPressed, setSubmitButtonPressed] = useState(false);
  const [input, setInput] = useState({
    brandId: "",
    serviceUnit: "",
    priceId: "",
  });
  const [errors, setErrors] = useState({
    brandId: "",
    serviceUnit: "",
    priceId: "",
  });

  const fetchAllBrands = () => {
    setLoading(true);
    getAllBrands()
      .then((response) => {
        if (!response.data.error) {
          const res = response.data.map((item) => ({
            label: item.name,
            value: item.id,
          }));
          setBrandList(res);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  const handleBrandSelection = (selectedItem) => {
    fetchGetBrandPriceCode(selectedItem.value);
  }

  const handleBrandPriceCode = () => {
    fetchGetServiceUnit();
  };

  const fetchGetBrandPriceCode = (brandId) => {
    getBrandPriceCode(brandId)
      .then((response) => {
        if (!response.data.error) {
          const res = response.data;
          // const priceList = res.prices;
          const filteredData = res.prices.filter(
            (price) => price.status === true
          );
          const priceList = filteredData.map((item) => ({
            label: item.priceCode,
            value: item.id,
          }));
          setBrandPriceList(priceList);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

   const fetchGetServiceUnit = () => {
     getServiceUnit()
       .then((response) => {
         if (!response.data.error) {
           const res = response.data
             .filter(
               (item) => item === "SqFt" || item === "Mtr" || item === "Qty"
             ) // Keep only desired items
             .map((item) => ({
               label: item === "Qty" ? "Piece" : item, // Rename "Qty" to "Piece"
               value: item === "Qty" ? "Qty" : item, // Keep "Qty" as value, otherwise use item as both label and value
             }));
           setServiceUnitList(res);
         }
       })
       .catch((err) => {
         console.error("Error:", err);
       });
   };
   
  useEffect(() => {
    fetchAllBrands();
  }, []);

  const handlePriceGarmentUpload = (event) => {
    event.preventDefault();
    setSubmitButtonPressed(true);
    const fileInput = event.target.querySelector('input[type="file"]');
    const uploadFile = fileInput.files[0];

    if (!uploadFile) {
      console.error("No file selected");
      return;
    }

    AddPriceGarmentServiceUpload(uploadFile, input.priceId.value, input.serviceUnit.value)
      .then((response) => {
        // handle the response
        if (response.status === true) {
          successMessage("Garment Price Upload Successfully");
        } else {
          successMessage("Garment Price Upload Failed");
        }
        setSubmitButtonPressed(false);
         navigate("/")
      })
      .catch((error) => {
        // handle errors
        errorMessage("Garment Price Upload Failed");
      });
  };

  return (
    <div className="form-demo background-image-login">
      <Navbar
        expand="lg"
        className="bg-body-tertiary"
        style={{
          backgroundImage: "linear-gradient(to bottom, #1752aa, #2b70d7)",
        }}
      >
        <Container>
          <Button
            style={{ background: "none", border: "none", left: -20 }}
            onClick={() => navigate(-1)}
          >
            <i className="pi pi-arrow-left" style={{ fontSize: "2rem" }}></i>
          </Button>
          <Navbar.Brand href="/">
            <img style={{ width: "46px" }} src="/Img/1.png" alt="logo" />
          </Navbar.Brand>

          <Navbar.Toggle />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="me-auto-new">
              <Nav.Link className="nav-link-design" href="/">
                Home
              </Nav.Link>
              <Nav.Link className="nav-link-design" href="/logout">
                Logout
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container fluid>
        <Row>
          <Col style={{ margin: 10 }}>
            <div>
              <div style={{ textAlign: "center" }}>
                <h3>Add Price Garment Upload</h3>
              </div>
              <main id="main" className="main mb-3">
                <section className="section">
                  <div className="col-lg-12">
                    <div>
                      <div className="mt-3">
                        <Dropdown
                          value={input.brandId}
                          options={brandList}
                          style={{ width: "100%" }}
                          onChange={(e) => {
                            setInput({
                              ...input,
                              brandId: e.target.value,
                            });
                            handleBrandSelection(e.target.value);
                          }}
                          optionLabel="label"
                          placeholder="Select a Brand"
                        />
                        {/*<div className="text-danger mt-1">{errors.priceId}</div>*/}
                      </div>
                      {input.brandId && (
                        <div>
                          <div className="mt-3">
                            <Dropdown
                              value={input.priceId}
                              options={brandPriceList}
                              style={{ width: "100%" }}
                              onChange={(e) => {
                                setInput({
                                  ...input,
                                  priceId: e.target.value,
                                });
                                 handleBrandPriceCode();
                              }}
                              optionLabel="label"
                              placeholder="Select a Tariff List"
                            />
                            <div className="text-danger mt-1">
                              {errors.priceId}
                            </div>
                          </div>
                          {input.priceId && (
                            <div>
                              <div className="mt-3">
                                <Dropdown
                                  value={input.serviceUnit}
                                  options={serviceUnitList}
                                  style={{ width: "100%" }}
                                  onChange={(e) => {
                                    setInput({
                                      ...input,
                                      serviceUnit: e.target.value,
                                    });
                                   
                                  }}
                                  optionLabel="label"
                                  placeholder="Select a Service Unit"
                                />
                                <div className="text-danger mt-1">
                                  {errors.serviceUnit}
                                </div>
                              </div>
                              {input.serviceUnit && (
                                <form onSubmit={handlePriceGarmentUpload}>
                                  <div className="row mb-3">
                                    <div
                                      className="col-md-12 mt-4"
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div></div>
                                      <div>
                                        <label
                                          for="file-upload"
                                          className="custom-file-upload"
                                        >
                                          <i className="pi pi-upload"></i>{" "}
                                          Choose Price Garment File
                                        </label>
                                        <input
                                          id="file-upload"
                                          type="file"
                                          onChange={handleFileChange}
                                          style={{ display: "none" }}
                                        />
                                      </div>
                                      <div>
                                        <i
                                          className="pi pi-file"
                                          style={{
                                            color: "blue",
                                            fontSize: "40px",
                                          }}
                                        ></i>
                                        {fileName && (
                                          <p
                                            style={{
                                              alignSelf: "center",
                                              fontSize: 14,
                                              fontWeight: 600,
                                              margin: 10,
                                            }}
                                          >
                                            {fileName}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  {selectedImage && (
                                    <div className="col-md-12 text-center">
                                      <button
                                        type="submit"
                                        className="move-btn move-btn-su"
                                        disabled={submitButtonPressed}
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  )}
                                </form>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </section>
              </main>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AddPriceGarmentService;
